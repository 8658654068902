import React from "react";

import { css, keyframes, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import Button from "../NewForms/Button";
import HeadLine from "../NewHeadline";
import useStore, { SnackbarResolveFn } from "./store";

const SnackbarContainer = styled.div`
    position: fixed;
    z-index: 1000;
    right: 1rem;
    bottom: 1rem;
    min-width: 344px;
    padding: 10px;
    margin: 16px;
    ${({ theme }) => css`
        background: ${theme.colors.blue.toString()};
    `}

    border-radius: 4px;
    max-width: 672px;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
        0 1px 18px 0 rgb(0 0 0 / 12%);
    overflow: hidden;
    ${({ theme }) => css`
        color: ${theme.colors.blue.toString()};
    `}
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Close = styled.div`
    ::before {
        padding: 0.5em;
        cursor: pointer;
        content: "✕";
    }
`;

const Actions = styled.div`
    display: flex;
    gap: 10px;
    justify-content: end;
`;

const Body = styled.div`
    padding-bottom: 1em;
`;

interface Props {
    onResolve: SnackbarResolveFn;
}

const Snackbar = () => {
    const { title, message, onResolve, isVisible, isConfirm, type } =
        useStore();
    const theme = useTheme();
    const bgColors = {
        success: theme.colors.green.lighten(1.3).toString(),
        error: theme.colors.red.lighten(1.1).toString(),
        default: theme.colors.white.toString(),
    };

    const bgColor = bgColors[type];

    return isVisible ? (
        <SnackbarContainer
            css={css`
                background-color: ${bgColor};
                animation: 300ms ease-out forwards ${slideUp};
            `}
        >
            <Header>
                <HeadLine lookLike={4} color="blue">
                    {title}
                </HeadLine>
                <HeadLine lookLike={4} color="blue">
                    <Close onClick={() => onResolve(false)} />
                </HeadLine>
            </Header>

            <Body>{message}</Body>

            <Actions>
                {isConfirm && <Confirm onResolve={onResolve} />}
                {!isConfirm && <Notification onResolve={onResolve} />}
            </Actions>
        </SnackbarContainer>
    ) : null;
};

const Confirm = ({ onResolve }: Props) => {
    const { options } = useStore();
    return (
        <>
            <Button small onClick={() => onResolve(true)}>
                {options.confirmText}
            </Button>
            <Button small onClick={() => onResolve(false)} color="redOutline">
                {options.confirmCancelText}
            </Button>
        </>
    );
};

const Notification = ({ onResolve }: Props) => {
    const { options } = useStore();
    return (
        <Button small onClick={() => onResolve(true)}>
            {options.notifyText}
        </Button>
    );
};

export default Snackbar;
